import { Show, For } from 'solid-js';
import { Picture, TextLink } from '@troon/ui';
import { IconFlag, IconGlobe, IconNavigation, IconPhone } from '@troon/icons';
import { Dynamic } from 'solid-js/web';
import { getConfigValue } from '../../modules/config';
import { gql } from '../../graphql';
import type { FacilityLocationFragment } from '../../graphql';
import type { IconProps } from '@troon/icons';
import type { ParentProps, Component } from 'solid-js';

type Props = {
	facility: FacilityLocationFragment;
};

export function FacilityLocation(props: Props) {
	return (
		<div class="flex flex-col gap-6 @container/loc">
			<Picture
				src={`https://${getConfigValue('MAP_HOST')}/course/${props.facility.slug}/map.png`}
				alt={`Street map of ${props.facility.name} and its local surroundings.`}
				sizes={[[800, 400]]}
				class="rounded"
			/>

			<h2 class="sr-only">Course details</h2>
			<div class="flex flex-col divide-y divide-solid divide-neutral-500 @xl/loc:flex-row @xl/loc:divide-x @xl/loc:divide-y-0">
				<Show when={(props.facility.courses?.length ?? 0) > 1}>
					<Details title="Courses" icon={IconFlag}>
						<ul>
							<For each={props.facility.courses}>{(course) => <li>{course.name}</li>}</For>
						</ul>
					</Details>
				</Show>

				<Show when={props.facility.metadata?.address}>
					{(address) => (
						<Details title="Address" icon={IconNavigation}>
							<address
								class="not-italic"
								aria-label={`Address for ${props.facility.name}`}
								itemprop="address"
								itemscope
								itemtype="https://schema.org/PostalAddress"
							>
								<span itemprop="streetAddress">{address().street}</span>
								<br />
								<span itemprop="addressLocality">{address().city}</span>,{' '}
								<span itemprop="addressRegion">{address().state}</span>{' '}
								<span itemprop="postalCode">{address().postalCode}</span>
							</address>
						</Details>
					)}
				</Show>

				<Show when={props.facility.metadata?.phone}>
					{(phone) => (
						<Details title="Phone" icon={IconPhone}>
							<p>
								<TextLink href={`tel:${phone()}`}>{phone()}</TextLink>
							</p>
						</Details>
					)}
				</Show>

				<Show when={props.facility.metadata!.website}>
					{(website) => (
						<Details title="Website" icon={IconGlobe}>
							<p>
								<TextLink href={website()} target="_blank">
									{new URL(website()).hostname}
								</TextLink>
							</p>
						</Details>
					)}
				</Show>
			</div>
		</div>
	);
}

type DetailsProps = ParentProps<{
	title: string;
	icon: Component<IconProps>;
}>;

function Details(props: DetailsProps) {
	return (
		<div class="flex flex-row items-center justify-between gap-2 py-4 @xl/loc:basis-1/3 @xl/loc:flex-col-reverse @xl/loc:items-start @xl/loc:justify-end @xl/loc:px-6 @xl/loc:first:ps-0 @xl/loc:last:pe-0">
			<div>
				<h3 class="font-semibold">{props.title}</h3>
				{props.children}
			</div>
			<span class="flex size-10 items-center justify-center rounded bg-brand-100 p-1 text-xl text-brand">
				<Dynamic component={props.icon} />
			</span>
		</div>
	);
}

gql(`fragment FacilityLocation on Facility {
	slug
  name
  courses {
    name
  }
  metadata {
    address {
      street
      street2
      city
      state
      country
      postalCode
    }
    phone
    website
  }
}`);
